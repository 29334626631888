import { Injectable, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private appStartedSource = new Subject<void>();
    private clientSiteSource = new Subject<string>();
    private mainRouteSource = new Subject<string>();
    private clientSite: string;

    appStarted$ = this.appStartedSource.asObservable();
    clientUpdated$ = this.clientSiteSource.asObservable();
    mainRouteUpdated$ = this.mainRouteSource.asObservable();

    constructor(private router: Router) {
        this.clientSite = sessionStorage.getItem('agency');
        this.clientSiteSource.next(this.clientSite);
        router.events
            .pipe(filter((e) => e instanceof NavigationEnd))
            .subscribe((e: NavigationEnd) => {
                let urlTree = this.router.parseUrl(this.router.url);
                let segments = urlTree.root.children['primary'].segments;
                if (segments.length >= 2 && (segments[0].path === 'sites')) {
                    this.clientSite = segments[1].path;
                    this.clientSiteSource.next(this.clientSite);
                    sessionStorage.setItem('agency', this.clientSite);
                    this.mainRouteSource.next(segments[0].path);
                }
            });
    }

    startApp() {
        this.appStartedSource.next();
    }
}
