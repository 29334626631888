import { Inject, Injectable } from '@angular/core';
import { User, UserInfo } from '../../models/shared/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
    NotificationService,
    NotificationType,
    NotificationCloseType,
} from 'src/app/services/shared/notification.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionTimeoutComponent } from '../../components/session-timeout/session-timeout.component';
import { AgencyService } from './agency.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private _idToken$ = new Subject<string>();
    private _isLoggedIn;
    private _userInfo: UserInfo;
    private _idToken:string;
    public _user: User;
    public sessionContinued: boolean = false;
    private silentSSO = false;
    public IsLoggedIn = new BehaviorSubject<boolean>(false);
    public userInfo = new BehaviorSubject<UserInfo>(new UserInfo());
    public user$ = new BehaviorSubject<any>(null)
    public loginDisplay$ = new Subject<boolean>();
    public account$ = new Subject<any>();
    public signInSuccess$ = new Subject<boolean>();

    public scope = {
        scopes: [],
        account: this.authService.instance.getActiveAccount()
      }

    loginStatusUpdated$ = this.IsLoggedIn.asObservable();

    constructor(
        public http: HttpClient,
        private notificationService: NotificationService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private broadcastService: MsalBroadcastService,
        private authService: MsalService,
        private _tokenService: TokenService,
        private router: Router,
        private dialog : MatDialog,
        private agencyService: AgencyService
    ) {
        this._userInfo = new UserInfo();
        this._user = new User();
        this._isLoggedIn = false;
        this.IsLoggedIn.next(this._isLoggedIn);

        this._tokenService.resetAccessToken();

        this.broadcastService.inProgress$
        .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
        .subscribe(() => {
            this.checkAndSetActiveAccount();
        })

        this.broadcastService.msalSubject$
		.pipe(
			filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.LOGIN_SUCCESS)
		)
		.subscribe((result: EventMessage) => {
            let idToken = (result.payload as any).idToken;
            if (idToken) {
                this._idToken$.next(idToken)
            }
            if (result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                this.silentSSO = true;
            }
		});

        this._idToken$.subscribe((idToken) => {
            if (idToken) {
                this._tokenService.setIdToken(idToken);
                this.authorize(idToken)
            }
        })

        this.user$.subscribe((user) => {
            if(user) {
                this._tokenService.setAccessToken(user.accessToken);
                this.setLoginDisplay();
                this.signInSuccess$.next(true);
            }
        })

        this._idToken = this._tokenService.getIdToken()
        if (this._idToken) {
            this.silentSSO = true;
            this.authorize(this._idToken);
        }

        this._tokenService.showMessage$.subscribe((showSessionTimeoutComponent) => {
            if(showSessionTimeoutComponent) {
                const dialogRef = this.dialog.open(SessionTimeoutComponent, {disableClose: true, closeOnNavigation: false});
                this._tokenService.resetTimer$.next(true);
            }
        })
    }

    setLoginDisplay(): void {
        this.loginDisplay$.next(this.authService.instance.getAllAccounts().length > 0);
    }

    authorize(idToken: string) {
        return this.http.get(`${environment.api.baseURL}/Authorize`, {
            headers: new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + idToken)
        }).subscribe(user => {
            if(user) {
                this.mapFieldsToUserObj(user)
                this.user$.next(user)
                if (!this.silentSSO) {
                    this.loginNotification(true);
                }
            }
            else {
                this.loginNotification(false);
            }
        });
    }

    loginRedirect() {
        if (this.msalGuardConfig.authRequest){
            let loginRequest = {...this.msalGuardConfig.authRequest, extraQueryParameters: {"khtsAppName":"tredlitevmt"}} as RedirectRequest
            this.authService.loginRedirect(loginRequest);
          } else {
            this.authService.loginRedirect();
          }
    }

    logoutRedirect() {
        this._tokenService.sessionSignout();
        this.authService.logoutRedirect({
            postLogoutRedirectUri: `${environment.b2c.postLogoutRedirectUri}/sites/${this.agencyService.clientSite}/`
          });
    }

    getLoggedIn = () => {
        this.IsLoggedIn.next(this._isLoggedIn);
        return this._isLoggedIn;
    };

    getUser = () => {
        return this._user;
    };

    checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0])
            this.account$.next(this.authService.instance.getActiveAccount())
        } else {
            this.account$.next(activeAccount)
        }
    }

    mapFieldsToUserObj(user) {
        this._user.accessToken = user.accessToken
        this._user.userId = user.userId
        this._user.firstName = user.firstName
        this._user.lastName = user.lastName
        this._user.email = user.userEmail
        this._user.agencyId = user.agencyId
    }

    getUserFromStorage = () => {
        this._userInfo = JSON.parse(sessionStorage.getItem('_userInfo'));
        this.userInfo.next(this._userInfo);
        this._isLoggedIn = true;
        this.IsLoggedIn.next(this._isLoggedIn);
    }

    loginNotification(signInSuccess: boolean) {
        if (signInSuccess) {
            this.notificationService.showNotification(
                NotificationType.Success,
                "Successful Log In",
                NotificationCloseType.Self | NotificationCloseType.DeleteButton
            );
        } else {
            this.notificationService.showNotification(
                NotificationType.Error,
                "No Account Found, Please contact an Adminstrator.",
                NotificationCloseType.Self | NotificationCloseType.DeleteButton
            );
        }
    }

    setSessionContinued(): void {
        this.sessionContinued = true;
    }
}
