import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeWithSpaces'
})

export class CapitalizeWithSpacesPipe implements PipeTransform {

    uppercaseKeywords: string[] = ['mrn', 'dma', 'qsr', 'dsl', 'gas'];

    transform(value: string): string {
        let formattedValue = this.addSpacesAfterKeywords(value);
        return this.capitilzeAndAddSpacesToCamelCase(formattedValue.trim());
    }

    addSpacesAfterKeywords(value: string): string {
        for (let keyword of this.uppercaseKeywords) {
            let regex = new RegExp(keyword, 'i');
            if (regex.test(value)) {
                value = value.replace(regex, ' ' + keyword.toUpperCase());
            }
        }
        return value;
    }

    capitilzeAndAddSpacesToCamelCase(value: string): string {
        value = this.capitalizeFirstLetter(value);
        value = this.addSpaceBeforeCapitalLetters(value);
        value = this.addSpaceBeforeNumbersAfterQ(value);
        value = this.addSpaceAfterUppercaseKeywords(value);
        return value;
    }

    capitalizeFirstLetter(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    addSpaceBeforeCapitalLetters(value: string): string {
        return value.replace(/([a-z])([A-Z0-9])/g, '$1 $2');
    }

    addSpaceBeforeNumbersAfterQ(value: string): string {
        return value.replace(/([0-9]Q)([0-9])/g, '$1 $2');
    }

    addSpaceAfterUppercaseKeywords(value: string): string {
        for (let keyword of this.uppercaseKeywords) {
            let regex = new RegExp(keyword.toUpperCase(), 'g');
            value = value.replace(regex, keyword.toUpperCase() + ' ');
        }
        return value;
    }
}
