import { Injectable } from '@angular/core';
import { from, of, Subject } from 'rxjs';
import { IImage } from '../vmt/image.service';
import { ClassBreakInfo } from 'src/app/models/shared/map.model';

export enum FeatureSelectorType {
    Single,
    Box,
    Shape
}

@Injectable({
    providedIn: 'root'
})
export class MapInteractionService {
    // Observable sources
    private featureSelectorSource = new Subject<FeatureSelectorType>();
    private clearSelectionSource = new Subject<void>();
    private undoSelectionSource = new Subject<void>();
    private selectionCompleteSource = new Subject<void>();
    private takeScreenshotSource = new Subject<void>();
    private takeScreenshotCompletedSource = new Subject<IImage>();
    private uploadShapefileSource = new Subject<string>();
    private selectionToTazRequestedSource = new Subject<{hasPriority: boolean }>();
    private selectionToTazCompletedSource = new Subject<any[]>();
    private widgetsToggledSource = new Subject<void>();
    private centerOnSelectionSource = new Subject<void>();
    private goToSource = new Subject<number[]>();
    private shapefileToTazRequestedSource = new Subject<void>();
    private priorityLayerUpdatedSource = new Subject<{layerTitle: string, percent: number}>();
    private highlightUpdateSource = new Subject<any[]>();
    private highlightSetSource = new Subject<any[]>();
    private tazVisibilityChangeSource = new Subject<boolean>();
    private parcelVisibilityChangeSource = new Subject<boolean>();
    private togglePriorityLayersSource = new Subject<boolean>();
    private classBreakUpdateSource = new Subject<{layer:string, valueExpression: string, classBreakInfo: ClassBreakInfo}>();
    private mapLoadSource = new Subject<void>();
    private cityChangeSource = new Subject<string>();
    private initializePinDropSource = new Subject<void>();

    // Observable streams
    featureSelectorUpdated$ = this.featureSelectorSource.asObservable();
    clearSelectionStarted$ = this.clearSelectionSource.asObservable();
    undoSelectionStarted$ = this.undoSelectionSource.asObservable();
    selectionCompleted$ = this.selectionCompleteSource.asObservable();
    takeScreenshotRequested$ = this.takeScreenshotSource.asObservable();
    takeScreenshotCompleted$ = this.takeScreenshotCompletedSource.asObservable();
    uploadShapefileRequested$ = this.uploadShapefileSource.asObservable();
    selectionToTazRequested$ = this.selectionToTazRequestedSource.asObservable();
    selectionToTazCompleted$ = this.selectionToTazCompletedSource.asObservable();
    widgetsToggled$ = this.widgetsToggledSource.asObservable();
    centerOnSelectionRequested$ = this.centerOnSelectionSource.asObservable();
    goToRequested$ = this.goToSource.asObservable();
    shapefileToTazRequested$ = this.shapefileToTazRequestedSource.asObservable();
    priorityLayerUpdated$ = this.priorityLayerUpdatedSource.asObservable();
    highlightUpdated$ = this.highlightUpdateSource.asObservable();
    highlightSet$ = this.highlightSetSource.asObservable();
    tazVisibilityChanged$ = this.tazVisibilityChangeSource.asObservable();
    parcelVisibilityChanged$ = this.parcelVisibilityChangeSource.asObservable();
    togglePriorityLayersRequested$ = this.togglePriorityLayersSource.asObservable();
    classBreakUpdated$ = this.classBreakUpdateSource.asObservable();
    mapLoaded$ = this.mapLoadSource.asObservable();
    cityChanged$ = this.cityChangeSource.asObservable();
    initializePinDropRequested$ = this.initializePinDropSource.asObservable();

    constructor() { }

    updateFeatureSelectorType(selectorType: FeatureSelectorType) {
        this.featureSelectorSource.next(selectorType);
    }

    clearSelection() {
        this.clearSelectionSource.next();
    }

    undoSelection() {
        this.undoSelectionSource.next();
    }

    completeSelection() {
        this.selectionCompleteSource.next();
    }

    takeScreenshot() {
        this.takeScreenshotSource.next();
    }

    completeScreenshot(image: IImage) {
        this.takeScreenshotCompletedSource.next(image);
    }

    uploadShapefile(filename: string) {
        this.uploadShapefileSource.next(filename);
    }

    getTazFromSelection(options: {hasPriority: boolean }) {
        this.selectionToTazRequestedSource.next(options);
    }

    getTazFromShapefile() {
        this.shapefileToTazRequestedSource.next();
    }

    setTazFromSelection(ids: any[]) {
        this.selectionToTazCompletedSource.next(ids);
    }

    toggleWidgets() {
        this.widgetsToggledSource.next();
    }

    centerOnSelection() {
        this.centerOnSelectionSource.next();
    }

    mapGoTo(point: number[]) {
        this.goToSource.next(point);
    }

    updatePriorityLayerCoverage(layerTitle: string, percent: number) {
        this.priorityLayerUpdatedSource.next({layerTitle: layerTitle, percent: percent});
    }

    updateHighlights(highlights: any[]) {
        this.highlightUpdateSource.next(highlights);
    }

    setHighlights(highlights: any[]) {
        this.highlightSetSource.next(highlights);
    }

    notifyTazVisibilityChanged(isVisible: boolean) {
        this.tazVisibilityChangeSource.next(isVisible);
    }

    notifyParcelVisibilityChanged(isVisible: boolean) {
        this.parcelVisibilityChangeSource.next(isVisible);
    }

    togglePriorityLayers(show: boolean) {
        this.togglePriorityLayersSource.next(show);
    }

    addLayerClassBreaks(layer:string, valueExpression: string, classBreakInfo: ClassBreakInfo) {
        this.classBreakUpdateSource.next({layer, valueExpression, classBreakInfo})
    }

    notifyMapLoaded() {
        this.mapLoadSource.next();
    }

    updateCity(city: string) {
        this.cityChangeSource.next(city);
    }

    initializePinDrop() {
        this.initializePinDropSource.next();
    }
}
