<div class="columns columns-no-margin">
    <div class="column app-fullheight" [ngClass]="{'is-6': page >= constants.PAGE_CALCULATE, 'is-8': page === constants.PAGE_TDM}" >
        <div class="is-flex is-flex-direction-column fullheight">
            <div [ngSwitch]="page" class="col-left is-relative" style="flex: 1;" [class.tdm-column]="page === constants.PAGE_TDM"
                >
                <div *ngSwitchCase="constants.PAGE_LOCATION">
                    <p class="title is-4 mb-2">Choose Your Location</p>
                    <p class="mb-2">Select parcels by choosing them on the map, searching the address, or uploading a
                        project boundary shapefile.</p>
                    <div>
                        <div class="field">
                            <label class="label">Jurisdiction</label>
                            <ng-select class="mw-120" [(ngModel)]="selectedCity" [items]="cities" bindLabel="CityName"
                                bindValue="CityId" (change)="onCityChange($event)" [clearable]="false" placeholder="Search"
                                [class.is-danger]="!testValidation('jurisdiction') && isValidating">
                            </ng-select>
                            <p class="help is-danger"
                                [class.is-invisible]="testValidation('jurisdiction') || !isValidating">The jurisdiction is required.</p>
                        </div>
                        <div class="field">
                            <label class="label">Address</label>
                            <ng-select [items]="locatorResult$ | async" bindLabel="text" [addTag]="false"
                                [multiple]="false" [hideSelected]="true" [trackByFn]="locatorTrackByFn"
                                [minTermLength]="2" [loading]="locatorResultLoading"
                                typeToSearchText="Please enter 2 or more characters to search"
                                [typeahead]="locatorResultInput$" [(ngModel)]="selectedLocatorResults"
                                (change)="onLocatorChange($event)" placeholder="Search">
                            </ng-select>
                        </div>
                        <label class="label">Parcel Selection</label>
                        <article id="zoomMsg" class="message is-danger">
                            <div class="message-body message-body-zoom">
                                <strong>Zoom in on the map to the parcel level to select the parcels.</strong>
                            </div>
                          </article>
                        <div class="field radio-list">
                            <input class="is-checkradio is-accent" id="radioParcel" type="radio" name="radioMap"
                                [(ngModel)]="mapSource" [value]="1" (change)="onMapSourceChanged()">
                            <label for="radioParcel"><b>Select From Map</b></label>
                            <input class="is-checkradio is-accent" id="radioShapefile" type="radio" name="radioMap"
                                [(ngModel)]="mapSource" [value]="2">
                            <label for="radioShapefile"><b>Upload Project Shapefile</b></label>
                        </div>
                        <div *ngIf="mapSource == 1" class="tabs is-toggle is-fullwidth">
                            <ul>
                                <li>
                                    <a (click)="selectByPoint()">
                                        <span class="icon-text">
                                            <span class="icon">
                                                <i class="fas fa-mouse-pointer" aria-hidden="true"></i>
                                            </span>
                                            <span>Single</span>
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="selectByRectangle()">
                                        <span class="icon-text">
                                            <span class="icon">
                                                <i class="far fa-square" aria-hidden="true"></i>
                                            </span>
                                            <span>Box</span>
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="selectByPolygon()">
                                        <span class="icon-text">
                                            <span class="icon">
                                                <i class="fas fa-draw-polygon" aria-hidden="true"></i>
                                            </span>
                                            <span>Shape</span>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="mapSource == 1" class="columns">
                            <div class="column">
                                <button class="button is-fullwidth is-rounded" (click)="onUndoClick()">Undo</button>
                            </div>
                            <div class="column">
                                <button class="button is-fullwidth is-rounded" (click)="onClearClick()">Clear</button>
                            </div>
                        </div>
                        <div *ngIf="mapSource == 2" id="uploader" class="file has-name is-fullwidth">
                            <form enctype="multipart/form-data" method="post" id="uploadForm" style="width:100%;">
                                <div class="field">
                                    <label class="file-label">
                                        <input class="file-input" type="file" name="file" id="inFile" (change)="onFileChange($event)">
                                        <span class="file-cta">
                                            <span class="file-icon">
                                                <i class="fas fa-upload" style=" vertical-align: middle;"></i>
                                            </span>
                                            <span class="file-label">&nbsp;&nbsp;Select File</span>
                                        </span>
                                        <span class="file-name">{{filename}}
                                        </span>
                                    </label>
                                    <p class="help" id="upload-status"></p>
                                </div>
                            </form>
                        </div>
                        <p class="help is-danger" [class.is-invisible]="testValidation('parcel') || !isValidating">Select at least one parcel to continue.</p>
                    </div>
                </div>
                <div *ngSwitchCase="constants.PAGE_INFO">
                    <p class="title is-4 mb-2">Project Information</p>
                    <div>
                        <div class="field">
                            <label class="label">Project Name</label>
                            <div class="control">
                                <input class="input" type="text" placeholder="Enter a project name"
                                    [(ngModel)]="projectName"
                                    [class.is-danger]="!testValidation('project-name') && isValidating">
                            </div>
                            <p class="help is-danger"
                                [class.is-invisible]="testValidation('project-name') || !isValidating">The project name
                                is required.</p>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Analysis Year</label>
                                    <ng-select *ngIf="hasInterpolation" [(ngModel)]="analysisYear" [items]="analysisYears" [clearable]="false"></ng-select>
                                    <ng-select *ngIf="!hasInterpolation" [(ngModel)]="analysisYearNoInterpolation" (change)="noInterpolationYearChange()" bindLabel="title"
                                        [items]="analysisYearsNoInterpolation" [clearable]="false" [searchable]="false"></ng-select>
                                </div>
                                <div class="field" *ngIf="!agencyConfig.hasHomeBasedWork">
                                    <label class="label">VMT/Service Population{{hasVt ? " (Project Delta) " : ""}}</label>
                                    <input class="input" type="number" min="0" [(ngModel)]="cityVmtPerServicePopulation">
                                    <p class="help is-danger" [class.is-invisible]="testValidation('vmt-service-pop') || !isValidating">
                                        The VMT/Service Population must be greater than 0.
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <!-- <div class="field">
                                    <label class="label">Project Context/Setting</label>
                                    <ng-select [(ngModel)]="projectContext" [items]="projectContexts" [clearable]="false"></ng-select>
                                </div> -->
                                <div class="field" *ngIf="!agencyConfig.hasHomeBasedWork && hasVt">
                                    <label class="label">VT/Service Population{{hasVt ? " (Project Delta) " : ""}}</label>
                                    <input class="input" type="number" min="0" [(ngModel)]="cityVtPerServicePopulation">
                                    <p class="help is-danger" [class.is-invisible]="testValidation('vt-service-pop') || !isValidating">
                                        The VT/Service Population must be greater than 0.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">ITE Trip Gen Land Use
                                <span *ngIf="agencyConfig.agencyID == 'ec23cbc2-0460-45c0-b91e-9cde9c7024cb'" class="icon-text" style="vertical-align: text-bottom;">
                                    <span class="icon has-text-info is-clickable" (click)="onIteInfoClick()">
                                        <i class="fas fa-lg fa-info-circle"></i>
                                    </span>
                                </span>
                            </label>
                            <ng-select [items]="landUses" bindLabel="displayName" [loading]="landUseLoading"
                                [(ngModel)]="selectedLandUse" (change)="selectedLandUseChange()" placeholder="Search">
                            </ng-select>
                            <p id="aq-help" class="help" style="background: #FFDD57; color:black" [class.is-invisible]="landUseHasAirQuality">&nbsp;&nbsp;This land use does not have Air Quality info.</p>
                        </div>
                        <div class="columns">
                            <div class="column is-3">
                                <div class="field">
                                    <label class="label">Land Use Quantity</label>
                                    <div class="control">
                                        <input id="size-input" class="input" type="number" min="0"
                                            [ngModel]="selectedSize" (ngModelChange)="onSelectedSizeChange($event)" (keydown)="onKeyDown($event)">
                                    </div>
                                    <p id="size-help" class="help is-danger is-invisible">The land use quantity is required.</p>
                                </div>
                            </div>
                            <div class="column is-3 is-align-self-center">
                                <label class="label pad-t-7">{{selectedLandUse?.defaultUnit}}</label>
                            </div>
                        </div>
                        <div class="buttons">
                            <button class="button is-primary is-outlined" (click)="onAddLandUseClick()" [disabled]="!selectedLandUse?.isUsed">Add Land Use</button>
                            <article class="message is-info mb-2" *ngIf="selectedLandUse && !selectedLandUse?.isUsed">
                                <div [ngSwitch]="selectedLandUse.iteCode">
                                    <div *multiSwitchCases="['225', '226', '227', '310', '311', '312', '320', '411', '520', '522', '525', '565', '911', '912', '944', '945', '945a', '945b', '945c', '945d', '945e', '945f', '947', '948', '949']"
                                        class="message-body message-body-land-use">This land use cannot be evaluated using this tool. Note that this land use may qualify for project type screening and be presumed to have a less than significant impact.</div>
                                    <div *ngSwitchDefault class="message-body px-2 py-2 ">This land use cannot be evaluated using this tool.</div>
                                </div>
                            </article>
                        </div>
                        <div style="max-height: 34px; overflow-y: scroll;">
                            <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                <thead class="has-background-primary" [class.has-background-danger]="!testValidation('land-uses') && isValidating">
                                    <tr>
                                        <th class="has-text-white has-text-centered">ITE Trip Gen Land Use</th>
                                        <th class="has-text-white has-text-centered col-number">Quantity</th>
                                        <th class="has-text-white has-text-centered col-number">Units</th>
                                        <th class="col-action"></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div class="table-container" style="max-height: 166px;">
                            <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                <thead class="has-background-primary" style="visibility: collapse;">
                                    <tr>
                                        <th class="has-text-white has-text-centered">ITE Trip Gen Land Use</th>
                                        <th class="has-text-white has-text-centered col-number">Quantity</th>
                                        <th class="has-text-white has-text-centered col-number">Units</th>
                                        <th class="col-action"></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="landUseList?.length !== 0">
                                    <tr *ngFor="let landUse of landUseList">
                                        <td>{{ landUse.displayName }}</td>
                                        <td class="col-number">{{ landUse.intensity }}</td>
                                        <td class="col-number">{{ landUse.defaultUnit }}</td>
                                        <td class="has-text-centered col-action">
                                            <i class="table-action table-action-delete far fa-trash-alt"
                                                (click)="onDeleteLandUseClick(landUse.rowId)"></i>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="landUseList?.length === 0 && !isValidating">
                                    <tr>
                                        <td colspan="5" class="no-data-available">No land uses added.</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="landUseList?.length === 0 && isValidating">
                                    <tr>
                                        <td colspan="5" class="no-data-available has-text-danger">Add at least one land use.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="constants.PAGE_INTERNAL_CAPTURE">
                    <p class="title is-4 mb-2">Internal Capture</p>
                    <label>Internal Capture Method: </label>
                    <div class="field radio-list">
                        <input class="is-checkradio is-accent" id="radioNchrp" type="radio" name="radioCapture"
                            [(ngModel)]="captureMethod" [value]="1" (change)="onCaptureMethodChanged($event)">
                        <label for="radioNchrp"><b>NCHRP 684</b></label>
                        <input class="is-checkradio is-accent" id="radioCustom" type="radio" name="radioCapture"
                            [(ngModel)]="captureMethod" [value]="2">
                        <label for="radioCustom"><b>Custom</b></label>
                    </div>
                    <div *ngIf="captureMethod === 1" >
                        <table class="table is-striped is-bordered is-narrow is-fullwidth">
                            <thead class="has-background-primary">
                                <tr>
                                    <th class="has-text-white has-text-centered">Land Use</th>
                                    <th class="has-text-white has-text-centered col-number">Daily Trips</th>
                                    <th class="has-text-white has-text-centered col-number">Internal Trips</th>
                                    <th class="has-text-white has-text-centered col-number">Internal Capture %</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of landUseList">
                                    <td>{{row.displayName}}</td>
                                    <td>{{row.trips | number:'1.0-0'}}</td>
                                    <td>{{row.internalTrips | number:'1.0-0'}}</td>
                                    <td>{{row.internalCapture | number:'1.2-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="captureMethod === 2">
                        <p>Enter custom Internal Capture percent for each land use.</p>
                        <table class="table is-striped is-bordered is-narrow is-fullwidth">
                            <thead class="has-background-primary">
                                <tr>
                                    <th class="has-text-white has-text-centered">Land Use</th>
                                    <th class="has-text-white has-text-centered col-number">Daily Trips</th>
                                    <th class="has-text-white has-text-centered col-number">Internal Trips</th>
                                    <th class="has-text-white has-text-centered">Internal Capture %</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of landUseList">
                                    <td style="vertical-align: middle;">{{row.displayName}}</td>
                                    <td style="vertical-align: middle;">{{row.trips | number:'1.0-0'}}</td>
                                    <td style="vertical-align: middle;">{{row.internalTrips | number:'1.0-0'}}</td>
                                    <td>
                                        <div class="field">
                                            <p class="control has-icons-right">
                                                <input class="input m-1 tdm-input" type="number" min="0" max="100" step="0.01" [ngModel]="row.internalCapture" (ngModelChange)="onCustomInternalCaptureChange($event, row.id)">
                                                <span class="icon is-small is-right">
                                                    <i class="fas fa-percentage"></i>
                                                </span>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <article *ngIf="totalTrips > 2500" class="message is-warning my-1">
                        <div class="message-body px-2 py-1 font-09">This project generates more than 2,500 daily trips. Please consult with {{agencyConfig.agencyAddress ?? 'the lead agency'}} whether this tool is appropriate for use for analyzing the VMT impact of your project.</div>
                    </article>
                </div>
                <div class="fullheight" *ngSwitchCase="constants.PAGE_CALCULATE">
                    <p class="title is-4 mb-2">Analysis</p>
                    <div class="columns fullheight" style="font-size: 0.9rem">
                        <div class="column fullheight">
                            <div class="content is-overflow-y is-absolute results-absolute">
                                <p><b>Project Name:</b>&nbsp;{{projectName}}</p>
                                <p><b>Location:&nbsp;</b>{{selectedCity ? (selectedCity | keyValue:cities:'CityId':'CityName')
                                    : (selectedLocatorResults? selectedLocatorResults.text : null)}}
                                    <span *ngIf="!selectedCity && !selectedLocatorResults"><i>N/A</i></span>
                                </p>
                                <p><b>Analysis Year:</b>&nbsp;{{analysisYear}}</p>
                                <p *ngIf="!agencyConfig.hasHomeBasedWork"><b>{{hasVt ? "Citywide " : ""}}VMT/Service Population{{hasVt ? " (Project Delta) " : ""}}:</b>&nbsp;{{cityVmtPerServicePopulation}}</p>
                                <p *ngIf="!agencyConfig.hasHomeBasedWork && hasVt"><b>{{hasVt ? "Citywide " : ""}}VT/Service Population{{hasVt ? " (Project Delta) " : ""}}:</b>&nbsp;{{cityVtPerServicePopulation}}</p>
                                <label><b>Project Land Use & Intensities:</b></label>
                                <div class="mb-1" *ngIf="landUseList.length; else noLandUses">
                                    <table class="table is-striped is-bordered is-narrow is-fullwidth" *ngIf="agencyConfig.hasHomeBasedWork">
                                        <thead class="has-background-primary">
                                            <tr>
                                                <th class="has-text-white has-text-centered">Land Use</th>
                                                <th class="has-text-white has-text-centered col-number">Quantity</th>
                                                <th class="has-text-white has-text-centered">Units</th>
                                                <th class="has-text-white has-text-centered col-number">Per Capita/Employee VMT</th>
                                                <th class="has-text-white has-text-centered col-number">VMT With Mitigation</th>
                                                <th class="has-text-white has-text-centered col-number">Total VMT</th>
                                                <th class="has-text-white has-text-centered col-number">Threshold</th>
                                                <th class="has-text-white has-text-centered">Significant Impact</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let row of landUseList">
                                                <td>{{row.iteCode}}</td>
                                                <td>{{row.intensity}}</td>
                                                <td>{{row.defaultUnit}}</td>
                                                <td>{{row.vmt | number:'1.1-1'}}</td>
                                                <td>{{row.vmtWithMitigation | number:'1.1-1'}}</td>
                                                <td>{{row.totalVmt | number:'1.1-1'}}</td>
                                                <td>{{row.threshold | number:'1.1-1'}}</td>
                                                <td>{{row.significantImpact}}</td>
                                            </tr>
                                        </tbody>
                                        <!-- Footer with totals for Project, Mitigation, Total VMT  -->
                                        <tfoot>
                                            <tr>
                                                <th class="has-text-right" colspan="5">Totals</th>
                                                <!-- <td>{{getLandUseListSumVmt() | number:'1.1-1'}}</td>
                                                <td>{{getLandUseListSumVmtMitigation() | number:'1.1-1'}}</td> -->
                                                <td>{{getLandUseListSumVmtTotal() | number:'1.1-1'}}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <table class="table is-striped is-bordered is-narrow is-fullwidth" *ngIf="!agencyConfig.hasHomeBasedWork">
                                        <thead class="has-background-primary">
                                            <tr>
                                                <th class="has-text-white has-text-centered">Land Use</th>
                                                <th class="has-text-white has-text-centered col-number">Quantity</th>
                                                <th class="has-text-white has-text-centered">Units</th>
                                                <th class="has-text-white has-text-centered col-number">Daily Trips</th>
                                                <!-- <th class="has-text-white has-text-centered col-number">Average Trip Length</th> -->
                                                <th class="has-text-white has-text-centered col-number">Total VMT</th>
                                                <th class="has-text-white has-text-centered col-number">Attributable VMT/SP %</th>
                                                <th class="has-text-white has-text-centered col-number">Attributable VMT/SP</th>
                                                <th *ngIf="hasVt" class="has-text-white has-text-centered col-number">Attributable VT/SP %</th>
                                                <th *ngIf="hasVt" class="has-text-white has-text-centered col-number">Attributable VT/SP</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let row of landUseList; let i = index">
                                                <td>{{row.iteCode}}</td>
                                                <td>{{row.intensity}}</td>
                                                <td>{{row.defaultUnit}}</td>
                                                <td>{{(output[i] ? output[i].dailyTripsProject : 0) | number}}</td>
                                                <!-- <td>{{row.averageTripLength | number:'1.1-1'}}</td> -->
                                                <td>{{output[i] ? (output[i].totalVmt| number:'1.1-1') : 0 }}</td>
                                                <td>{{output[i] ? (output[i].attributableVmt / output[i].vmtPerServicePop * 100 | number:'1.1-1') : 0}}</td>
                                                <td>{{output[i] ? (output[i].attributableVmt| number:'1.1-1') : 0}}</td>
                                                <td *ngIf="hasVt">{{output[i] ? (output[i].attributableVt / output[i].vtPerServicePop * 100| number:'1.1-1') : 0}}</td>
                                                <td *ngIf="hasVt">{{output[i] ? (output[i].attributableVt| number:'1.1-1') : 0}}</td>
                                            </tr>
                                        </tbody>
                                        <!-- Footer with totals for Project, Mitigation, Total VMT  -->
                                        <tfoot>
                                            <tr>
                                                <th class="has-text-right" colspan="3">Totals</th>
                                                <td>{{getLandUseListSumDailyTrips() | number:'1.1-1'}}</td>
                                                <!-- <td></td> -->
                                                <td>{{getLandUseListSumVmtTotal() | number:'1.1-1'}}</td>
                                                <td></td>
                                                <td>{{cityVmtPerServicePopulation}}</td>
                                                <td *ngIf="hasVt"></td>
                                                <td *ngIf="hasVt">{{cityVtPerServicePopulation}}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <ng-template #noLandUses><p class="ml-5 mb-1"><i>No selected land uses.</i></p></ng-template>

                                <label><b>Total Emission Estimates:</b></label>
                                <article class="message is-info my-1">
                                    <div class="message-body px-2 py-1 font-09">For planning level only, not for use in CEQA air quality analysis.</div>
                                </article>
                                <table class="table is-striped is-bordered is-narrow is-fullwidth mb-1">
                                    <thead class="has-background-primary">
                                        <tr>
                                            <th class="has-text-white has-text-centered">Pollutant</th>
                                            <th class="has-text-white has-text-centered col-number">Mobile</th>
                                            <th class="has-text-white has-text-centered col-number">Mitigation</th>
                                            <th class="has-text-white has-text-centered col-number">With Mitigation</th>
                                            <th class="has-text-white has-text-centered col-number">Non Mobile</th>
                                            <th class="has-text-white has-text-centered col-number">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>CO (lb/day)</td>
                                            <td>{{totalAirQuality.COMobile| number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.COMobile - totalAirQuality.MitigationCO | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.MitigationCO | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.CO | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.CO + totalAirQuality.MitigationCO | number:'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>ROG (lb/day)</td>
                                            <td>{{totalAirQuality.ROGMobile| number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.ROGMobile - totalAirQuality.MitigationROG | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.MitigationROG | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.ROG | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.ROG + totalAirQuality.MitigationROG | number:'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>NOX (lb/day)</td>
                                            <td>{{totalAirQuality.NOXMobile| number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.NOXMobile - totalAirQuality.MitigationNOX | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.MitigationNOX | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.NOX | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.NOX + totalAirQuality.MitigationNOX | number:'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>SOX (lb/day)</td>
                                            <td>{{totalAirQuality.SOXMobile| number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.SOXMobile - totalAirQuality.MitigationSOX | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.MitigationSOX | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.SOX | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.SOX + totalAirQuality.MitigationSOX | number:'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>PM2.5 (lb/day)</td>
                                            <td>{{totalAirQuality.PM2_5Mobile| number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.PM2_5Mobile - totalAirQuality.MitigationPM2_5 | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.MitigationPM2_5 | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.PM2_5| number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.PM2_5 + totalAirQuality.MitigationPM2_5 | number:'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>PM10 (lb/day)</td>
                                            <td>{{totalAirQuality.PM10Mobile| number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.PM10Mobile - totalAirQuality.MitigationPM10 | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.MitigationPM10 | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.PM10 | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.PM10 + totalAirQuality.MitigationPM10 | number:'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>CO<sub>2</sub> (mt/year)</td>
                                            <td>{{totalAirQuality.CO2Mobile| number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.CO2Mobile - totalAirQuality.MitigationCO2 | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.MitigationCO2 | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.CO2 | number:'1.2-2'}}</td>
                                            <td>{{totalAirQuality.CO2 + totalAirQuality.MitigationCO2 | number:'1.2-2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="!agencyConfig.hasHomeBasedWork && priorityResults.length > 0">
                                    <p *ngIf="priorityResults.length > 1"><b>{{agencyConfig.priorityLayers[1].description}}</b>&nbsp;{{priorityResults[1].percent > 0 ? 'Yes' : 'No'}}({{priorityResults[1].percent | number: '1.1-1'}}%)</p>
                                    <p><b>{{agencyConfig.priorityLayers[0].description}}</b>&nbsp;{{priorityResults[0].percent > 0 ? 'Yes' : 'No'}}({{priorityResults[0].percent | number: '1.1-1'}}%)</p>
                                </div>
                                <div *ngIf="agencyConfig.hasHomeBasedWork"><b>Project Presumptions of Less than Significant Impact </b>
                                    <span class="icon-text" style="vertical-align: bottom;">
                                        <span class="icon has-text-info is-clickable" (click)="onPresumptionsInfoClick()">
                                            <i class="fas fa-lg fa-info-circle"></i>
                                        </span>
                                    </span>
                                </div>
                                <div *ngIf="defaultPresumptions.length && agencyConfig.hasHomeBasedWork">
                                    <ul id="ulProjectPresumptions" style="list-style-type: none;">
                                        <li *ngFor="let p of defaultPresumptions" style="overflow: hidden;">
                                            <mat-checkbox *ngIf="p.isProject" [checked]="p.isSelected" (change)="onCheckboxClick($event, p.id)">{{p.title}}</mat-checkbox>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fullheight" *ngSwitchCase="constants.PAGE_TDM">
                    <p class="title is-4 mb-2">Transportation Demand Management (TDM)</p>
                    <article class="message mb-1">
                        <div class="message-body message-body-zoom">
                            VMT can only be mitigated in each group by up to the stated Group Max Reduction. The current reduction listed for each group must not exceed the max reduction for its respective group.
                            <div class="mb-1"></div>
                            <a href="https://www.caleemod.com/documents/handbook/full_handbook.pdf" target="_blank">CAPCOA Handbook
                                <span><i class="fas fa-external-link-alt"></i></span>
                            </a>
                            <div class="mb-1"></div>
                            The Global Maximum Reduction is based on the 2010 CAPCOA Mitigation Handbook reported maximum.
                        </div>
                    </article>

                    <div class="message is-primary mb-2">
                        <div class="message-header"><p>Selected Land Use: {{this.landUseList.length ? this.landUseList[this.currentTabIndex].displayName : ''}}</p></div>
                        <div class="message-body msg-body-tdm">
                            <div class="content">
                                <p class="has-text-weight-bold mb-1">Global Maximum Reduction: 20%</p>
                                <p class="mb-1"><span class="has-text-weight-bold">Current Reduction: </span>{{selectedTdmReduction[currentTabIndex] | number:'1.2-2'}}%</p>
                            </div>
                        </div>
                    </div>
                    <mat-accordion multi>
                        <mat-expansion-panel disabled>
                            <mat-expansion-panel-header class="mat-expansion-panel-header-auto" collapsedHeight="*" expandedHeight="*">
                                <table class="table is-narrow is-fullwidth">
                                    <thead>
                                        <tr>
                                            <!-- <th style="border:0; width: 30px;" class="has-text-centered">#</th> -->
                                            <th style="border:0; width: 200px;" class="has-text-centered">TDM Measure</th>
                                            <th style="border:0; width: 250px;" class="has-text-centered">Description</th>
                                            <th style="border:0; width: 55px;" class="has-text-centered">Max VMT Reduction</th>
                                            <th style="border:0; width: 130px;" class="has-text-centered">Input</th>
                                            <th style="border:0; width: 200px;" class="has-text-centered">Input Definition</th>
                                            <th style="border:0; width: 50px;" class="has-text-centered">Reduction</th>
                                        </tr>
                                    </thead>
                                </table>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                        <mat-expansion-panel *ngFor="let g of selectedTdm; let gi = index">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span class="is-size-6">
                                        {{g.GroupName}} - Group Max Reduction: {{g.MaxReduction * 100}}%, Current Reduction: {{g.CurrentReduction | number:'1.2-2'}}%
                                    </span>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <span class="icon-text">
                                        <span *ngIf="g.MaxReduction === 0.2" class="icon has-text-info cursor-default" matTooltip="Research indicates that outside of dense urban areas the largest achievable reduction in VMT is 20%.
                                            While individual measures may indicate that their maximum VMT reduction is above 20%, this tool only allows up to a 20% reduction due to location context.">
                                            <i class="fas fa-lg fa-info-circle"></i>
                                        </span>
                                    </span>
                                  </mat-panel-description>
                            </mat-expansion-panel-header>
                            <table class="table is-striped is-narrow is-hoverable is-fullwidth">
                                <tbody>
                                    <tr *ngFor="let s of g.Strategy; let si = index">
                                        <td style="width: 200px; vertical-align: middle;">{{s.TDM}}
                                            <span *ngIf="s.TDMID == 19" class="icon-text" style="vertical-align: middle;">
                                                <span class="icon has-text-info" matTooltip="The recommended study area for this mitigation is 0.6 mile around the pedestrian network improvement.
                                                    When improving sidewalks, a best practice is to ensure they are contiguous and link externally with existing and planned pedestrian facilities.
                                                    Thus, if the pedestrian improvement does not link to existing pedestrian facilities (existing sidewalk length is 0) then no VMT reduction is calculated.">
                                                    <i class="fas fa-lg fa-info-circle"></i>
                                                </span>
                                            </span>
                                        </td>
                                        <td style="width: 250px; vertical-align: middle;">{{s.TDMDescription}}</td>
                                        <td style="width: 55px; vertical-align: middle;">{{s.SelectedMaxValue | percent}}</td>
                                        <td style="width: 130px;">
                                            <div *ngFor="let i of s.StrategyInputList">
                                                <input *ngIf="i.InputType === 'Text'" class="input m-1 tdm-input" type="text" [(ngModel)]="i.Input" (change)="onTdmInputChange(gi,si)"
                                                    [disabled]="(s.IsEmployeeOnly && isTdmResidential) || (s.IsResidentialOnly && !isTdmResidential) || s.IsDisabled">
                                                <input *ngIf="i.InputType === 'Number'" class="input m-1 tdm-input" type="number" [(ngModel)]="i.Input" (change)="onTdmInputChange(gi,si)" max="{{i.InputMax > 0 ? i.InputMax : 9999}}"
                                                    [disabled]="(s.IsEmployeeOnly && isTdmResidential) || (s.IsResidentialOnly && !isTdmResidential) || s.IsDisabled">
                                                <div *ngIf="i.InputType === 'Money'">
                                                    <div class="field">
                                                        <p class="control has-icons-left">
                                                            <input class="input m-1 tdm-input" type="number" min="0" step="0.01" [(ngModel)]="i.Input" (change)="onTdmInputChange(gi,si)" max="{{i.InputMax > 0 ? i.InputMax : 9999}}"
                                                            [disabled]="(s.IsEmployeeOnly && isTdmResidential) || (s.IsResidentialOnly && !isTdmResidential) || s.IsDisabled">
                                                            <span class="icon is-small is-left">
                                                            <i class="fas fa-dollar-sign"></i>
                                                            </span>
                                                        </p>
                                                        </div>
                                                </div>
                                                <div *ngIf="i.InputType === 'Percent'">
                                                    <div class="field">
                                                        <p class="control has-icons-right">
                                                            <input class="input m-1 tdm-input" type="number" min="0" max="100" [(ngModel)]="i.Input" (change)="onTdmInputChange(gi,si)"
                                                            [disabled]="(s.IsEmployeeOnly && isTdmResidential) || (s.IsResidentialOnly && !isTdmResidential) || s.IsDisabled">
                                                            <span class="icon is-small is-right">
                                                            <i class="fas fa-percentage"></i>
                                                            </span>
                                                        </p>
                                                        </div>
                                                </div>
                                                <ng-select *ngIf="i.InputType === 'Boolean'" class="tdm-select m-1" appendTo="body" [(ngModel)]="i.Input"
                                                    [clearable]="false" (change)="onTdmInputChange(gi,si)"
                                                    [disabled]="(s.IsEmployeeOnly && isTdmResidential) || (s.IsResidentialOnly && !isTdmResidential) || s.IsDisabled">
                                                    <ng-option value="Yes">Yes</ng-option>
                                                    <ng-option value="No">No</ng-option>
                                                </ng-select>
                                                <ng-select *ngIf="i.InputType === 'LessOrMoreThan'" class="tdm-select m-1" appendTo="body" [(ngModel)]="i.Input"
                                                    [clearable]="false" (change)="onTdmInputChange(gi,si)"
                                                    [disabled]="(s.IsEmployeeOnly && isTdmResidential) || (s.IsResidentialOnly && !isTdmResidential) || s.IsDisabled">
                                                    <ng-option *ngFor="let option of i.DropDownValues" [value]="option.Value">
                                                        {{option.Value}}</ng-option>
                                                </ng-select>
                                                <ng-select *ngIf="i.InputType === 'DropDown'" class="tdm-select m-1" appendTo="body" [(ngModel)]="i.Input"
                                                    [clearable]="false" (change)="onTdmInputChange(gi,si)"
                                                    [disabled]="(s.IsEmployeeOnly && isTdmResidential) || (s.IsResidentialOnly && !isTdmResidential) || s.IsDisabled">
                                                    <ng-option *ngFor="let option of i.DropDownValues" [value]="option.Value">
                                                        {{option.Value}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td style="width: 200px; font-size: 0.9em;">
                                            <div *ngFor="let i of s.StrategyInputList">
                                                <div class="input m-1 tdm-input tdm-input-fake">{{i.InputDefinition}}</div>
                                            </div>
                                        </td>
                                        <td style="width: 50px; vertical-align: middle;">{{s.Reduction | number:'1.2-2'}}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="fullHeight" *ngSwitchCase="constants.PAGE_BANK">
                    <p class="title is-4 mb-2">VMT Banking</p>
                    <div class="columns">
                        <div class="column">
                            <div class="field is-horizontal">
                                <div class="field-label is-normal field-label-mid">
                                    <label class="label">Banking/VMT Cost (VMT/$):</label>
                                </div>
                                <div class="field-body is-flex-grow-1">
                                    <div class="field">
                                        <p class="control">
                                            <input class="input" type="number" min="0" step="1" placeholder="0"
                                                [(ngModel)]="perUnitCost" [disabled]="!isBankTest || !hasFeasibleInput"
                                                (change)="onBankChange()">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field is-horizontal" *ngIf="isBankTest && hasFeasibleInput">
                                <div class="field-label is-normal ">
                                    <label class="label">Feasible Mitigation (%):</label>
                                </div>
                                <div class="field-body is-flex-grow-1">
                                    <div class="field">
                                        <p class="control">
                                            <input class="input" type="number" min="0" max="100" placeholder="0" step="1" [(ngModel)]="feasibleMitigation" (change)="onBankChange()">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column" style="max-height: 63vh; overflow: auto;">
                            <table class="table is-striped is-bordered is-narrow is-hoverable is-fullwidth" id="bankTable">
                                <thead class="has-background-primary">
                                    <tr>
                                        <th class="has-text-right has-text-white">ITE Code</th>
                                        <th class="has-text-white has-text-centered" style="width: 100px;" *ngFor="let u of landUseList;">{{u.iteCode}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Land Use Quantity</td>
                                        <td *ngFor="let u of landUseList">{{u.intensity}}</td>
                                    </tr>
                                    <tr>
                                        <td>Residences per Unit/Employees per 1000 Sq Ft</td>
                                        <td *ngFor="let u of landUseList">{{u.conversionFactor > 0 ? (u.conversionFactor | number:'1.2-2') : getPeopleRate(selectedCityObject, u.iteCode, 'Residential')}}</td>
                                    </tr>
                                    <tr *ngIf="agencyConfig.hasHomeBasedWork">
                                        <td>VMT/Unit*</td>
                                        <td *ngFor="let o of output">{{o.capitaProject | number:'1.1-1' }}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork">
                                        <td>VMT/SP</td>
                                        <td *ngFor="let o of output">{{o.vmtPerServicePop}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork && hasVt">
                                        <td>VT/SP</td>
                                        <td *ngFor="let o of output">{{o.vtPerServicePop}}</td>
                                    </tr>
                                    <tr *ngIf="isBankTest">
                                        <td>Total VMT</td>
                                        <td *ngFor="let b of landUseBanks">{{b.absoluteVmt | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork  && hasVt">
                                        <td>Initial Absolute VT</td>
                                        <td *ngFor="let b of landUseBanks">{{b.absoluteVt | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr *ngIf="agencyConfig.hasHomeBasedWork">
                                        <td>City of {{selectedCity | keyValue:cities:'CityId':'CityName'}} Threshold (VMT/Unit*)</td>
                                        <td *ngFor="let o of output">{{o.threshold | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork">
                                        <td>VMT Threshold</td>
                                        <td *ngFor="let o of output">{{o.vmtThreshold}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork && hasVt">
                                        <td>VT Threshold</td>
                                        <td *ngFor="let o of output">{{o.vtThreshold}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork && hasVt">
                                        <td>Feasible Mitigation (VT/SP)</td>
                                        <td *ngFor="let b of landUseBanks">{{b.feasibleMitigationVt | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork && hasVt">
                                        <td>Total Minimum Mitigation (VT/SP)</td>
                                        <td *ngFor="let b of landUseBanks">{{b.totalMinMitigationVt | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr *ngIf="agencyConfig.hasHomeBasedWork">
                                        <td>TDM Mitigation (VMT/Unit*)</td>
                                        <td *ngFor="let o of output">{{o.capitaReduction | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork">
                                        <td>TDM Mitigation (VMT/SP)</td>
                                        <td *ngFor="let o of output">{{o.vmtPerServicePop - o.vmtPerServicePopMitigation | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork && hasVt">
                                        <td>TDM Mitigation (VT/SP)</td>
                                        <td *ngFor="let o of output">{{o.vtPerServicePop - o.vtPerServicePopMitigation | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork && hasVt">
                                        <td>Remaining Mitigation Required (VT/SP)</td>
                                        <td *ngFor="let b of landUseBanks">{{b.remainingMitigationVt | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork && hasVt">
                                        <td>Remaining Absolute VT Mitigation Required (VT/SP)</td>
                                        <td *ngFor="let b of landUseBanks">{{b.remainingAbsoulteVt | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork && hasVt" class="has-text-weight-bold">
                                        <td>VT Mitigation Required Banking Cost ($)</td>
                                        <td *ngFor="let b of landUseBanks">{{b.costVt | number}}</td>
                                    </tr>
                                    <tr>
                                        <td>VMT/Unit* with TDM Mitigation</td>
                                        <td *ngFor="let o of output">{{o.capitaProjectWithMitigation | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{isBankTest ? 'Optional Full ' : 'VMT Bank '}}Mitigation <span *ngIf="agencyConfig.hasHomeBasedWork">(VMT/Unit*)</span><span *ngIf="!agencyConfig.hasHomeBasedWork">(VMT/SP)</span></td>
                                        <td *ngFor="let b of landUseBanks">{{b.optionalFullMitigation | number:'1.1-4'}}</td>
                                    </tr>
                                    <tr *ngIf="!isBankTest">
                                        <td>VMT Bank Absolute VMT</td>
                                        <td *ngFor="let b of landUseBanks">{{b.remainingAbsoulteVmt | number:'1.0-0'}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork && hasVt">
                                        <td>Optional Full Mitigation (VT/SP)</td>
                                        <td *ngFor="let b of landUseBanks">{{b.optionalFullMitigation | number:'1.1-1'}}</td>
                                    </tr>
                                    <tr class="has-text-weight-bold">
                                        <td>{{isBankTest && hasFeasibleInput ? 'Optional Full ' : ''}}Mitigation Banking Cost ($)</td>
                                        <td *ngFor="let b of landUseBanks">{{b.optionalFullCost | number}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork && hasVt" class="has-text-weight-bold">
                                        <td>Optional Full VT Mitigation Banking Cost ($)</td>
                                        <td *ngFor="let b of landUseBanks">{{b.optionalFullCostVt | number}}</td>
                                    </tr>
                                    <tr *ngIf="!agencyConfig.hasHomeBasedWork && hasVt">
                                        <td>Requires "Overriding Consideration" on Feasible VT Mitigation Only</td>
                                        <td *ngFor="let b of landUseBanks">{{b.requiresOverrideVt ? 'Yes' : 'No'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <article *ngIf="agencyConfig.hasHomeBasedWork" class="message is-info message-land-use">
                                <div class="message-body message-body-land-use">*Unit = Capita for Residential land uses. Unit = Employee for other land use types.</div>
                            </article>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field is-horizontal" *ngIf="isBankTest && hasFeasibleInput">
                                <div class="field-label is-normal is-flex-grow-3 field-label-mid">
                                    <label class="label">Total Mitigation Required Banking Cost ($)</label>
                                </div>
                                <div class="field-body is-flex-grow-1">
                                    <div class="field">
                                        <p class="control">
                                            <input class="input " readonly
                                            [ngModel]="totalBankCost | number" (ngModelChange)="totalBankCost = $event">
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!agencyConfig.hasHomeBasedWork && hasVt" class="field is-horizontal field-label-mid">
                                <div class="field-label is-normal is-flex-grow-3">
                                    <label class="label">Total VT Mitigation Required Banking Cost ($)</label>
                                </div>
                                <div class="field-body is-flex-grow-1">
                                    <div class="field">
                                        <p class="control">
                                            <input class="input " readonly
                                            [ngModel]="totalBankCostVt | number" (ngModelChange)="totalBankCostVt = $event">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field is-horizontal">
                                <div class="field-label is-normal is-flex-grow-3 field-label-mid" *ngIf="agencyConfig.agencyID !== 'ec23cbc2-0460-45c0-b91e-9cde9c7024cb'">
                                    <label class="label" [ngClass]="{'label-wide': isBankTest} ">Total{{isBankTest? ' Optional Full': ''}} Mitigation Banking Cost ($)</label>
                                </div>
                                <div class="field-label is-normal is-flex-grow-3 field-label-mid" *ngIf="agencyConfig.agencyID === 'ec23cbc2-0460-45c0-b91e-9cde9c7024cb'">
                                    <label class="label" class="label-wide">Total Mitigation Banking Cost ($)</label>
                                </div>
                                <div class="field-body is-flex-grow-1">
                                    <div class="field">
                                        <p class="control">
                                            <input class="input" readonly
                                            [ngModel]="totalOptionalBankCost | number" (ngModelChange)="totalOptionalBankCost = $event">
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!agencyConfig.hasHomeBasedWork && hasVt" class="field is-horizontal">
                                <div class="field-label is-normal is-flex-grow-3 field-label-mid">
                                    <label class="label">Total Optional Full VT Mitigation Banking Cost ($)</label>
                                </div>
                                <div class="field-body is-flex-grow-1">
                                    <div class="field">
                                        <p class="control">
                                            <input class="input" readonly
                                            [ngModel]="totalOptionalBankCostVt | number" (ngModelChange)="totalOptionalBankCostVt = $event">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fullHeight" *ngSwitchCase="constants.PAGE_PRIORITY">
                    <p class="title is-4 mb-2">Presumptions of Less Than Significant</p>
                    <p *ngIf="hasPriorityLayers">Check the checkbox if you want the project site to be considered covered by the priority area. Provide an explanation if overriding the analysis.</p>
                    <table *ngIf="hasPriorityLayers" class="table is-borderless mb-2 is-fullwidth">
                        <thead>
                            <tr>
                                <th class="has-text-centered">Area</th>
                                <th class="has-text-centered">Yes/No</th>
                                <th class="has-text-centered">Coverage (%)</th>
                                <th class="has-text-centered">Explanation For Override</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of priorityResults; let i = index">
                                <td>{{agencyConfig.priorityLayers[i].description}}</td>
                                <td><mat-checkbox [checked]="p.percent === 100" (click)="p.percent === 100 && !agencyConfig.hasPriFloorAreaRatio ? $event.preventDefault() : null" (change)="checkFar(p.layerTitle, $event)" layout="row" layout-align="center"></mat-checkbox></td>
                                <td class="has-text-right">{{p.percent | number: '1.1-1'}}</td>
                                <td><input class="input m-1 tdm-input" [class.is-hidden]="p.percent === 100"  [(ngModel)]="p.explanation"></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="columns" *ngIf="agencyConfig.hasPriFloorAreaRatio">
                        <div class="column is-6">
                            <div class="field is-horizontal">
                                <div class="field-label is-normal pt-2">
                                    <label class="label">Floor Area Ratio (FAR)</label>
                                </div>
                                <div class="field-body is-flex-grow-1">
                                    <div class="field">
                                        <p class="control">
                                            <input class="input" type="number" min="0" step="0.1" placeholder="0"
                                                [(ngModel)]="floorAreaRatio" [class.is-danger]="!testValidation('priority') && isValidating">
                                        </p>
                                        <p class="help is-danger"
                                            [class.is-invisible]="testValidation('priority') || !isValidating">The FAR must be at least 0.75.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content" *ngIf="agencyConfig.hasPriFloorAreaRatio">
                        <section>Restrictions for this screening include:</section>
                        <ul>
                            <li>Have a Floor Area Ratio (FAR) of less than 0.75; or</li>
                            <li>Includes more parking, excluding on-street parking, for use by residents, customers, or employees of the project than required by the City of El Segundo zoning code; or</li>
                            <li>Be inconsistent with the Sustainable Communities Strategy as determined by the City of El Segundo; or</li>
                            <li>Replaces affordable residential units with a smaller number of moderate- or high-income residential units</li>
                        </ul>
                    </div> -->

                    <div *ngFor="let p of tpaPrompts" class="field is-horizontal">
                        <div class="field-label is-normal field-label-left">
                            <label>{{p.question}}</label>
                        </div>
                        <div class="field is-narrow">
                            <div class="control">
                                <ng-select class="mw-120 no-search" [(ngModel)]="p.answer" [clearable]="false" [searchable]="false" (change)="onPresumptionPromptChange()">
                                    <ng-option value="Yes">Yes</ng-option>
                                    <ng-option value="No">No</ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <p class="has-text-weight-bold">Project Type Screening</p>
                    <div *ngFor="let p of projectPrompts" class="field is-horizontal">
                        <div class="field-label is-normal field-label-left">
                            <label class="is-left">{{p.question}}</label>
                        </div>
                        <div class="field is-narrow">
                            <div class="control">
                                <ng-select class="mw-120 no-search" [(ngModel)]="p.answer" [clearable]="false" [searchable]="false" (change)="onPresumptionPromptChange()">
                                    <ng-option value="Yes">Yes</ng-option>
                                    <ng-option value="No">No</ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="is-flex is-hidden" id="divScreenedOut">
                        <div class="columns is-mobile" style="width: 100%;">
                            <div class="column">
                                <article class="message is-success m-2">
                                    <div class="message-body px-2 py-2">Project is screened and can be presumed to result in a less than significant impact.</div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="is-flex">
                <div class="columns is-mobile" [class.is-hidden]="page !== constants.PAGE_CALCULATE" style="width: 100%;">
                    <div class="column">
                        <article *ngIf="page === constants.PAGE_CALCULATE && isAnyOverThreshold" class="message is-warning m-2">
                            <div class="message-body px-2 py-2" [innerHtml]="resultsWarning"></div>
                        </article>
                        <article *ngIf="page === constants.PAGE_CALCULATE && !isAnyOverThreshold" class="message is-success m-2">
                            <div class="message-body px-2 py-2">Project does not need to be mitigated and can be presumed to be less than significant.</div>
                        </article>
                    </div>
                </div>
            </div>
            <div class="is-flex is-justify-content-center p-1">
                <button *ngIf="page > constants.PAGE_LOCATION" class="button mw-120 mx-2 is-rounded" (click)="onBackClick()">
                    <span class="icon-text">
                        <span class="icon">
                            <i class="fas fa-arrow-left"></i>
                        </span>
                        <span>Back</span>
                    </span>
                </button>
                <button *ngIf="page === constants.PAGE_CALCULATE || page === constants.PAGE_BANK || page === constants.PAGE_TDM || page === constants.PAGE_PRIORITY"
                    class="button is-primary mw-120 mx-2 is-rounded" (click)="onPrintClick()" [disabled]="!isCalculateDisabled && page === constants.PAGE_PRIORITY">Print Results</button>
                <button *ngIf="page < constants.PAGE_BANK" class="button mw-120 mx-2 is-rounded" (click)="onNextClick()"
                    [class.is-primary]="nextText === 'Next' || nextText === 'Calculate'" [class.is-accent]="nextText === 'Mitigate VMT' || nextText === 'VMT Bank'"
                    [class.is-hidden]="nextText === ''" [disabled]="(nextText === 'Mitigate VMT' && !isAnyOverThreshold) || (nextText === 'Calculate' && isCalculateDisabled)">
                    <span class="icon-text">
                        <span class="icon">
                            <i class="fas fa-arrow-right"></i>
                        </span>
                        <span>{{nextText}}</span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="column" [ngClass]="{'is-hidden': page < constants.PAGE_CALCULATE, 'is-6': page >= constants.PAGE_CALCULATE && page !== constants.PAGE_TDM, 'is-4': page === constants.PAGE_TDM}"
        style="border-left: 1px solid darkgray;">
        <mat-tab-group #graphTab color="accent" [(selectedIndex)]="graphTabIndex" (selectedTabChange)="graphSelectedTabChange($event)" (focusChange)="graphSelectedTabFocusChange($event)">
            <mat-tab *ngFor="let result of output" label="ITE {{result.iteCode}}">
                <div class="columns m-0 is-vcentered is-desktop" [style.display]="page >= constants.PAGE_CALCULATE ? 'block' : 'flex'">
                    <div class="column">
                        <div class="is-flex is-flex-direction-column app-fullheight-side-col box p-3">
                            <div class="chart-container" style="position: relative; flex: 1; overflow: hidden;">
                                <canvas id="vmtChart_{{result.iteCode}}_{{result.index}}" ></canvas>
                            </div>
                            <div style="position: relative; flex: 2; overflow-y: auto;">
                                <div style="font-size: 0.9rem;">
                                    <table *ngIf="agencyConfig.hasHomeBasedWork" class="table is-borderless mb-0">
                                        <tbody>
                                            <tr>
                                                <td class="has-text-right">Regional Average ({{result.landUseType === 'Residential' ? 'VMT/Capita' : 'HBW VMT/Emp' }}):</td>
                                                <td>{{result.average | number:'1.1-1'}}</td>
                                                <td class="has-text-right" *ngIf="averageReduction > 0">Threshold ({{averageReduction}}% below Average):</td>
                                                <td class="has-text-right" *ngIf="averageReduction === 0">Threshold:</td>
                                                <td>{{result.threshold | number:'1.1-1'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table *ngIf="agencyConfig.hasHomeBasedWork" class="table is-striped is-bordered is-narrow is-fullwidth">
                                        <thead class="has-background-primary">
                                            <tr>
                                                <th class="has-text-white has-text-centered">Metric</th>
                                                <th class="has-text-white has-text-centered">Project</th>
                                                <th class="has-text-white has-text-centered col-number">Mitigation</th>
                                                <th class="has-text-white has-text-centered col-number">With Mitigation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{result.isTotalVmt ? "Total VMT/Service Population" : (result.landUseType === 'Residential' ? 'VMT/Capita' : 'HBW VMT/Emp') }}</td>
                                                <td>{{(result.isTotalVmt ? result.totalVmt : result.capitaProject) | number:'1.1-1'}}</td>
                                                <td>{{result.capitaReduction | number:'1.1-1'}}</td>
                                                <td>{{(result.isTotalVmt ? result.totalVmtMitigation : result.capitaProjectWithMitigation)  | number:'1.1-1'}}</td>
                                            </tr>
                                            <tr>
                                                <td>Daily Trips</td>
                                                <td>{{result.dailyTripsProject | number: '1.0-0'}}</td>
                                                <td>{{result.dailyTripsReduction | number }}</td>
                                                <td>{{result.dailyTripsProjectWithMitigation | number}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table *ngIf="!agencyConfig.hasHomeBasedWork" class="table is-striped is-bordered is-narrow is-fullwidth">
                                        <thead class="has-background-primary">
                                            <tr>
                                                <th class="has-text-white has-text-centered">Metric</th>
                                                <th class="has-text-white has-text-centered">Project</th>
                                                <th class="has-text-white has-text-centered col-number">Mitigation</th>
                                                <th class="has-text-white has-text-centered col-number">With Mitigation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{hasVt ? "Citywide " : ""}}VMT/Service Population{{hasVt ? " (Project Delta) " : ""}}</td>
                                                <td>{{result.attributableVmt | number:'1.1-1'}}</td>
                                                <td>{{result.mitigationPercent * 100 | number:'1.2-2'}}%</td>
                                                <td>{{result.vmtPerServicePopMitigation | number:'1.1-1'}}</td>
                                            </tr>
                                            <tr *ngIf="hasVt">
                                                <td>{{hasVt ? "Citywide " : ""}}VT/Service Population{{hasVt ? " (Project Delta) " : ""}}</td>
                                                <td>{{result.attributableVt | number:'1.1-1'}}</td>
                                                <td>{{result.mitigationPercent * 100 | number:'1.2-2'}}%</td>
                                                <td>{{result.vtPerServicePopMitigation | number:'1.1-1'}}</td>
                                            </tr>
                                            <tr *ngFor="let priLayer of agencyConfig.priorityLayers; let i = index">
                                                <td>{{priLayer.metric}}</td>
                                                <td>{{priorityResults && priorityResults.length > i + 1 && priorityResults[i].percent > 0 ? 'Yes' : 'No'}}</td>
                                                <td>N/A</td>
                                                <td>N/A</td>
                                            </tr>
                                            <tr *ngIf="result.pedAccessibility > 0">
                                                <td>Ped Accessibility Score</td>
                                                <td>{{result.pedAccessibility}}</td>
                                                <td>N/A</td>
                                                <td>N/A</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <article *ngIf="!result.isPreexisting" class="message is-small is-info message-land-use">
                                        <div class="message-body message-body-land-use">This land use is not pre-existing.</div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="!agencyConfig.hasHomeBasedWork" label="Totals">
                <div class="columns m-0 is-vcentered is-desktop" [style.display]="page >= constants.PAGE_CALCULATE ? 'block' : 'flex'">
                    <div class="column">
                        <div class="is-flex is-flex-direction-column app-fullheight-side-col box box-p-3">
                            <div class="chart-container" style="position: relative; flex: 1; overflow: hidden;">
                                <canvas id="vmtChart_total" ></canvas>
                            </div>
                            <div style="position: relative; flex: 2; overflow-y: auto;">
                                <div style="font-size: 0.9rem;">
                                    <table *ngIf="!agencyConfig.hasHomeBasedWork" class="table is-striped is-bordered is-narrow mb-1 is-fullwidth">
                                        <thead class="has-background-primary">
                                            <tr>
                                                <th class="has-text-white has-text-centered">Metric</th>
                                                <th class="has-text-white has-text-centered">Project</th>
                                                <th class="has-text-white has-text-centered col-number">Mitigation</th>
                                                <th class="has-text-white has-text-centered col-number">With Mitigation</th>
                                                <th class="has-text-white has-text-centered col-number">Threshold</th>
                                                <th class="has-text-white has-text-centered">Potential Impact</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{hasVt ? "Citywide " : ""}}VMT/Service Population{{hasVt ? " (Project Delta) " : ""}}</td>
                                                <td>{{cityVmtPerServicePopulation}}</td>
                                                <td>{{getSummaryMitigationPercent() * 100 | number:'1.2-2'}}%</td>
                                                <td>{{getSummaryVmtMitigation() | number:'1.1-1'}}</td>
                                                <td>{{getSummaryVmtThreshold()}}</td>
                                                <td>{{getSummaryVmtMitigation() > getSummaryVmtThreshold() ? 'Yes' : 'No'}}</td>
                                            </tr>
                                            <tr *ngIf="hasVt">
                                                <td>{{hasVt ? "Citywide " : ""}}VT/Service Population{{hasVt ? " (Project Delta) " : ""}}</td>
                                                <td>{{cityVtPerServicePopulation}}</td>
                                                <td>{{getSummaryMitigationPercent() * 100 | number:'1.2-2'}}%</td>
                                                <td>{{getSummaryVtMitigation() | number:'1.1-1'}}</td>
                                                <td>{{getSummaryVtThreshold()}}</td>
                                                <td>{{getSummaryVtMitigation() > getSummaryVtThreshold() ? 'Yes' : 'No'}}</td>
                                            </tr>
                                            <tr *ngFor="let priLayer of agencyConfig.priorityLayers; let i = index">
                                                <td>{{priLayer.metric}}</td>
                                                <td>{{priorityResults && priorityResults.length > i + 1 && priorityResults[i].percent > 0 ? 'Yes' : 'No'}}</td>
                                                <td>N/A</td>
                                                <td>N/A</td>
                                                <td>{{priLayer.threshold}}</td>
                                                <td>{{priorityResults && priorityResults.length > i + 1 && priorityResults[i].percent == 0 ? 'Yes' : 'No'}}</td>
                                            </tr>
                                            <tr *ngIf="hasSummaryPedAccessibility()">
                                                <td>Ped Accessibility Score</td>
                                                <td>{{getSummaryPedAccessibility()}}</td>
                                                <td>N/A</td>
                                                <td>N/A</td>
                                                <td>{{getSummaryPedThreshold()}}</td>
                                                <td>{{getSummaryPedAccessibility() > getSummaryPedThreshold() ? 'Yes' : 'No'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<div>
    <div id="saveModal" class="modal">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="box">
                <div class="field">
                    <label class="label">Project Name</label>
                    <div class="control">
                        <input class="input" type="text" readonly [(ngModel)]="projectName">
                    </div>
                </div>
                <div class="field">
                    <label class="label">Project Description</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Enter project description" [(ngModel)]="projectFormDesc">
                    </div>
                </div>
                <div class="field is-grouped">
                    <p class="control">
                        <button class="button is-success" (click)="onNewSaveClick()">Save</button>
                    </p>
                    <p class="control">
                        <button class="button" (click)="onSaveModalClose()">Cancel</button>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div id="presumptionsModal" class="modal">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    <span class="icon-text">
                        <span class="icon has-text-info">
                          <i class="fas fa-info-circle"></i>
                        </span>
                        <span>Presumptions of Less than Significant Impact</span>
                    </span>
                </p>
                <button class="delete" aria-label="close" (click)="onPresumptionsModalClose()"></button>
            </header>
            <section class="modal-card-body">
                <app-presumptions-widget class="presumptions-widget" [showCheckbox]="false" [showTitle]="false">
                </app-presumptions-widget>
            </section>
            <footer class="modal-card-foot">
            </footer>
        </div>
    </div>
    <div id="iteModal" class="modal">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    <span class="icon-text">
                        <span class="icon has-text-info">
                          <i class="fas fa-info-circle"></i>
                        </span>
                        <span>ITE Trip Gen Land Uses</span>
                    </span>
                </p>
                <button class="delete" aria-label="close" (click)="onIteModalClose()"></button>
            </header>
            <section class="modal-card-body">
                <p class="has-text-justified">This tool uses the RIVCOM travel demand model to perform VMT analyses and does not use ITE trip generation to calculate VMT.
                    The ITE Trip Generation Land Uses are provided as options to the user to define their project and then these uses are converted into
                    RIVCOM model uses, where appropriate, to perform the VMT analysis. If land uses that are incompatible with the tool are selected,
                    this tool will not be able to estimate the VMT impact of the project and the user should work with the City of Riverside to determine alternative
                    analysis tools/methodologies.
                </p>
            </section>
            <footer class="modal-card-foot">
            </footer>
        </div>
    </div>
</div>
<div id="pageLoader" class="pageloader is-right-to-left app-fullheight"><span class="title">{{loaderText}}</span></div>
