export class VmtInfo
    {
        public projectName: string;
        public projectDescription: string;
        public location: string;
        public projectContextSetting: string;
        public county: string;
        public imageString: string;
        public analysisYear: number;
        public landUses: IteLandUse[] = [];
        public presumptions: Presumption[] = [];
        public emissions: Emission[] = [];
        public priorityAreas: PriorityArea[] = [];
        public agencyId: string;
        public isNchrp: boolean;
        public citywideVmt: number;
        public citywideVt: number;
        public apn: string;
        public averageReduction: number;

        public totalCO2: number;
        public totalROG: number;
        public totalNOX: number;
        public totalCO: number;
        public totalSOX: number;
        public totalPM10: number;
        public totalPM2_5: number;
    }

    export class IteLandUse
    {
        public code: string;
        public intensity: number;
        public unit: string;
        public chartImageString: string;
        public regionalAverage: number;
        public threshold: number;
        public vtThreshold: number;
        public pedAccessibilityThreshold: number;
        public pedAccessibilityScore: number;
        public isSignificant: string;

        public vmtName: string;
        public vtName: string;
        public vmtProject: number;
        public vtProject: number;
        public vtMitigation: number;
        public vmtProjectWithMitigation: number;
        public vtProjectWithMitigation: number;
        public totalVmt: number;
        public totalVt: number;

        public IsAffordableHousing: boolean;
        public IsLocalServing: boolean;

        public InternalCaptureRate: number;
        public tdmAsString?: string;

        public metrics: LandUseMetric[] = [];
        public emissions: LandUseEmission[] = [];
        public bankResults?: LandUseMetric[] = [];
    }

    export class Presumption
    {
        public name: string;
        public isChecked: boolean;
    }

    export class Emission
    {
        public name: string;
        public mobile: number;
        public withMitigation: number;
        public nonMobile: number;
    }

    class PriorityArea
    {
        public title: string;
        public description: string;
        public coverage: number;
        public explanation: string;
        public metric: string;
        public threshold: string;
    }

    export class LandUseMetric
    {
        public metricName: string;
        public project: number;
        public mitigation: number;
        public withMitigation: number;
    }

    export class LandUseEmission
    {
        public pollutantName: string;
        public mobile: number;
        public mitigation: number;
        public withMitigation: number;
        public nonMobile: number;
        public total: number;
    }

    export class PreScreenInfo
    {
        public projectName: string;
        public projectDescription: string;
        public location: string;
        public imageString: string;
        public analysisYear: number;
        public landUses: IteLandUse[] = [];
        public tpaPresumptions: Presumption[] = [];
        public projectPresumptions: Presumption[] = [];
        public apn: string;
    }
