export const environment = {
    production: true,
    baseURL: 'https://vmtstorage.z21.web.core.windows.net/',
    api: {
        baseURL: 'https://tredlite-dev.kimley-horn.com/api',
        rootURL: 'https://tredlite-dev.kimley-horn.com',
    },
    b2c: {
        authority: '',
        authorityDomain: '',
        clientId: '',
        b2cPolicies: {
            signIn: '',
        },
        postLogoutRedirectUri: 'https://tredlite-dev.kimley-horn.com'
    }
};