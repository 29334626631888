import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppConstants {
    public readonly PAGE_LOCATION = 1;
    public readonly PAGE_INFO = 2;
    public readonly PAGE_INTERNAL_CAPTURE = 3;
    public readonly PAGE_PRIORITY = 4;
    public readonly PAGE_CALCULATE = 5;
    public readonly PAGE_TDM = 6;
    public readonly PAGE_BANK = 7;

    public readonly PAGE_EV_START = 1;
    public readonly PAGE_EV_SCREEN = 2;
    public readonly PAGE_EV_SITE = 3;

    public readonly RES_AVG_TRIP_LENGTH = 12.5;
    public readonly WORK_AVG_TRIP_LENGTH = 7.3;
    public readonly PIN_SVG = 'M480.089 566Q509 566 529.5 545.411q20.5-20.588 20.5-49.5Q550 467 529.411 446.5q-20.588-20.5-49.5-20.5Q451 426 430.5 446.589q-20.5 20.588-20.5 49.5Q410 525 430.589 545.5q20.588 20.5 49.5 20.5ZM480 976Q319 839 239.5 721.5T160 504q0-150 96.5-239T480 176q127 0 223.5 89T800 504q0 100-79.5 217.5T480 976Z';
}
