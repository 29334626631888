import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/shared/authentication.service';
import { User } from 'src/app/models/shared/user.model';
import { Subject } from 'rxjs';
import { NavigationService } from 'src/app/services/shared/navigation.service';
import { takeUntil } from 'rxjs/operators';
import { SessionTimeoutComponent } from '../session-timeout/session-timeout.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
    public showBurgerMenu: boolean;
    public isLoggedIn: boolean = false;
    public userInfo: User = this.AuthenticationService.getUser();
    public isHoverable: boolean = true;
    public token: string;
    clientSite: string;
    logo = 'TREDLite_VMT_white.svg';
    private readonly _destroying$ = new Subject<boolean>();
    homeLink = [];


    constructor(
        private dialog : MatDialog,
        private AuthenticationService: AuthenticationService,
        private navigationService: NavigationService,
        ) {
            navigationService.clientUpdated$.subscribe(clientSite => this.clientSite = clientSite);
            navigationService.mainRouteUpdated$.subscribe(route => {
                switch(route) {
                    case 'sites':
                        this.logo = 'TREDLite_VMT_white.svg';
                        this.homeLink = ['/sites', this.clientSite, 'calculator'];
                        break;
                }
            });
        }

    ngOnInit(): void {

        this.AuthenticationService.loginDisplay$.pipe(takeUntil(this._destroying$))
        .subscribe(loginDisplay => {
            this.isLoggedIn = loginDisplay
        })
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(SessionTimeoutComponent);
    }

    ngOnDestroy(): void{
        this._destroying$.next(true);
        this._destroying$.complete();
    }

    updateLoggedIn = () =>{
        this.isLoggedIn = this.AuthenticationService.getLoggedIn();
    }

    onLogInClick() {
        this.AuthenticationService.loginRedirect();
    }


    onLogOutClick(){
        this.AuthenticationService.logoutRedirect();
    }

    onNavMenuClick($event) {
        // Make hover drop down menu go away
        this.isHoverable = false;
        // Put back hover drop down menu
        setTimeout(() => {
            this.isHoverable = true;
        }, 500);
    }

    onLogoClick() {
        this.navigationService.startApp();
    }
}
