import { Injectable } from '@angular/core';
import { Observable, from, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationService } from '../shared/notification.service';
import { BaseService } from '../shared/base-service.service';
import { environment } from 'src/environments/environment';
import { BankInput, VmtSettings } from 'src/app/models/vmt/vmt-settings.model';

@Injectable({
    providedIn: 'root'
})
export class CalculatorService extends BaseService {
    constructor(http: HttpClient, notificationService: NotificationService) {
        super(http, notificationService);
    }

    calculate(settings: VmtSettings) {
        return this.http.post(`${environment.api.baseURL}/CalculateVMT`, settings)
            .pipe(catchError(this.handleError('Calculate', [], false))
        );
    }

    calculateInternalCapture(settings: VmtSettings) {
        return this.http.post(`${environment.api.baseURL}/CalculateInternalCapture`, settings)
            .pipe(catchError(this.handleError('Internal Capture error', [], false))
        );
    }

    calculateBanking(settings: BankInput[]) {
        return this.http.post(`${environment.api.baseURL}/CalculateBanking`, settings)
            .pipe(catchError(this.handleError('Banking Calculation error', [], false))
        );
    }

    saveResults(results: any) {
        return this.http.post(`${environment.api.baseURL}/SaveProject`, results)
            .pipe(catchError(this.handleError('An error occurred while saving the project.', [], false))
        );
    }

    getProjects(userId: string) {
        let body = JSON.stringify(userId);
        return this.http.post(`${environment.api.baseURL}/GetProjects`, body, {
            headers: new HttpHeaders().set('Content-Type','application/json')
        })
            .pipe(catchError(this.handleError('An error occurred while getting the project list.', [], false))
        );
    }

    getProject(userId: string, projectId: string) {
        return this.http.post(`${environment.api.baseURL}/GetProject`, {userId: userId, projectId: projectId})
            .pipe(catchError(this.handleError('An error occurred while getting the project.', [], false))
        );
    }

    getReport(reportToGet: string, data: any) {
        return this.http.post(`${environment.api.baseURL}/reports/${reportToGet}`, data)
            .pipe(catchError(this.handleError('An error occurred while printing the project.', [], false))
        );
    }

    getVmtSericePopulation(agencyID: string, tazId: number) {
        return this.http.get<number>(`${environment.api.baseURL}/tazServicePopulation/${agencyID}/${tazId}`)
            .pipe(catchError(this.handleError<number>('Could not get the VMT/SP for this selection.', 0)));
    }
}
